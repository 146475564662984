import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import RelatedProducts from 'components/RelatedProducts';
import RelatedArticles from 'components/RelatedArticles';
import FaqAccordion from 'components/Faq/FaqAccordion';
import Image from 'common/Image';
import SectionTitle from 'common/SectionTitle';

import { ProductCategoryPageProps } from './models';
import './ProductCategoryPage.scss';

const ProductCategoryPage: FC<ProductCategoryPageProps> = ({
  pageContext: { areBreadcrumbsDisplayed, breadcrumb },
  data: {
    allProductCategory: {
      nodes: [productCategory],
    },
    allSitePage: { nodes: breadcrumbNodes },
  },
  location: { pathname },
}) => {
  const {
    name,
    headerImage,
    imageDescription,
    productsFinder,
    relatedProducts: [{ properties: relatedProductsData }],
    relatedArticles: [{ properties: relatedArticlesData }],
    faqSection: [{ properties: faqData }],
  } = productCategory;
  const breadcrumbs = createBreadcrumbs(
    breadcrumb.crumbs,
    areBreadcrumbsDisplayed,
    breadcrumbNodes,
    pathname
  );

  return (
    <Layout className="product-category-page" seo={productCategory} breadcrumbs={breadcrumbs}>
      <div className="header-section">
        <SectionTitle text={name} isMainTitle />
        <Image imageData={headerImage} alt={name} className="header-section__image" />
        <p className="header-section__description">{imageDescription}</p>
      </div>
      {productsFinder.map(({ properties }) => (
        <RelatedProducts
          key={properties.sectionName}
          className="product-category-page__products-finder"
          sectionLight={false}
          {...properties}
        />
      ))}
      <RelatedArticles
        className="product-category-page__related-articles"
        {...relatedArticlesData}
      />
      <FaqAccordion {...faqData} />
      <RelatedProducts
        className="product-category-page__related-products"
        sectionLight={false}
        {...relatedProductsData}
      />
    </Layout>
  );
};
export const query = graphql`
  query($id: String) {
    allProductCategory(filter: { id: { eq: $id } }) {
      nodes {
        ...SeoFragment
        id
        name
        imageDescription
        headerImage {
          ...ImageFragment
        }
        productsFinder {
          ...RelatedProductsFragment
        }
        relatedProducts {
          ...RelatedProductsWideFragment
        }
        relatedArticles {
          ...RelatedArticlesFragment
        }
        faqSection {
          ...FaqAccordionFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ProductCategoryPage;
